import { FC, useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Image,
  Box,
  Text,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdHomeWork } from 'react-icons/md';
import { useMsal } from '@azure/msal-react';
import { isMobile } from 'react-device-detect';
import { CartContext } from '../../../context/CartContext';
import { addProductRow } from '../../../helpers/api';
import { IApiError } from '../../../models/apierror';
import { tokenRequest } from '../../../authConfig';

export const ProductInfoModal: FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const modalSize = useBreakpointValue({ base: 'full', sm: 'md' });
  const { instance } = useMsal();

  const addToCart = async () => {
    if (state.selectedProduct === null) return;
    setIsSaving(true);
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const cart = await addProductRow(
        state.cart.id,
        state.selectedProduct.productCode,
        1,
        result.accessToken
      );
      dispatch({ type: 'UPDATE_CART', cart });
      dispatch({ type: 'NOTIFY', text: t('drawer.productAdded') });
      dispatch({ type: 'CLOSE_MODAL', cart });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsSaving(false);
      // eslint-disable-next-line no-console
      console.error('Failed to add product (productInfoModal)', e);
      // error code 003 means that the user should set product price by hand
      if (Array.isArray(e) && e.some((x: IApiError) => x.code === '003')) {
        const product = state.selectedProduct;
        dispatch({ type: 'CLOSE_MODAL' });
        dispatch({ type: 'OPEN_PRICE_MODAL', product });
      }
      // other error codes are errors that should show an error alert
    }
  };

  console.log('Info', 'modalSize', modalSize, isMobile ? '100vh' : modalSize);
  return (
    <Modal
      isOpen={state.showModal}
      onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
      size={modalSize}
      // mh="100%"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        style={
          isMobile
            ? {
                marginTop: '0px',
                minHeight: 'calc(100% - 69px)',
                maxHeight: 'calc(100% - 69px)',
              }
            : {}
        }
      >
        <ModalHeader>{state.selectedProduct?.productName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="full" overflow="hidden" mx="2">
            {state.selectedProduct?.imageUrl === null ? (
              <Box
                borderRadius="lg"
                bg="brand.primary"
                w="full"
                h="12rem"
                color="brand.pink"
              >
                <MdHomeWork style={{ height: '100%', width: '100%' }} />
              </Box>
            ) : (
              <Image
                src={state.selectedProduct?.imageUrl}
                alt={`${state.selectedProduct?.productName}`}
              />
            )}
            <Box pt="1">
              <Box py="2">
                <Text fontWeight="semibold">
                  {`${state.selectedProduct?.getFixedPrice()}`} €
                </Text>
              </Box>
              <Flex>
                <Box mr="2">{t('productInfo.code')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedProduct?.productCode}
                </Box>
              </Flex>
              <Flex>
                <Box mr="2">{t('productInfo.departmentCode')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedProduct?.departmentCode}
                </Box>
              </Flex>
              <Flex>
                <Box mr="2">{t('productInfo.departmentName')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedProduct?.departmentName}
                </Box>
              </Flex>
              <Flex>
                <Box mr="2">{t('productInfo.vatPercent')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedProduct?.vatPercent}%
                </Box>
              </Flex>
              <Flex>
                <Box mr="2">{t('productInfo.info')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedProduct?.infoText}
                </Box>
              </Flex>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button isFullWidth isLoading={isSaving} onClick={() => addToCart()}>
            {t('productInfo.add')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductInfoModal;
