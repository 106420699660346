import { FC, useState, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ModalConfirmationProps {
  headerText: string;
  bodyText: string;
  isOpen: any;
  onClose: any;
  onOk: any;
}

const ModalConfirmation: FC<ModalConfirmationProps> = ({
  headerText,
  bodyText,
  isOpen,
  onClose,
  onOk,
}) => {
  // const ModalConfirmation = ({ headerText, bodyText, isOpen, onClose, onOk }) => {
  const cancelRef = useRef(null);
  const { t, i18n } = useTranslation();

  const handleOK = async () => {
    onOk();
    onClose();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headerText}
            </AlertDialogHeader>
            <AlertDialogBody>{bodyText}</AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>{t('button.cancel')}</Button>
              <Button onClick={handleOK} ml={3}>
                {t('button.yes')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ModalConfirmation;
