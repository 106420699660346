import {
  Button,
  IconButton,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Flex,
  useToast,
  useBreakpointValue,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useContext, useState, useRef, useEffect } from 'react';
import { DeleteIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { CartContext } from '../../../context/CartContext';
import {
  initializePayco,
  addCashPayment,
  voidCart,
  initializeCart,
} from '../../../helpers/api';
import IPosDetails from '../../../models/posDetails';
import CartCustomerRow from '../CartCustomerRow';
import CartProductRows from '../CartProductRows';
import CartReceiptInfoRow from '../CartReceiptInfoRow';
import ModalYesOrNo from '../ModalYesOrNo';
import { tokenRequest } from '../../../authConfig';

// If true Cashpayment without Payco, only for testing purposes
const testing = false;

interface ICartDraweProps {
  posDetails: IPosDetails;
}

export const CartDrawer: FC<ICartDraweProps> = ({ posDetails }) => {
  const { t, i18n } = useTranslation();
  const { state, dispatch } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const drawerSize = useBreakpointValue({ base: 'full', md: 'md' });
  const toast = useToast({ variant: 'subtle' });
  const history = useHistory();
  const { instance } = useMsal();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const doCashPayment = async () => {
    try {
      setIsLoading(true);
      const tokenResult = await instance.acquireTokenSilent(tokenRequest);
      const paycoUrl = await addCashPayment(
        state.cart.id,
        '0',
        Math.round(state.cart.receiptTotal),
        tokenResult.accessToken
      );
      history.push(`/finalize/${state.cart.id}`);
    } catch (e) {
      setIsLoading(false);
      toast({
        title: t('drawer.checkoutFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed cash payment', e);
    }
  };

  const doCheckout = async () => {
    try {
      setIsLoading(true);

      if (state.cart.receiptTotal === 0 && state.cart.products.length > 0) {
        doCashPayment();
      } else {
        const tokenResult = await instance.acquireTokenSilent(tokenRequest);

        const paycoUrl = await initializePayco(
          state.cart,
          posDetails,
          tokenResult.accessToken
        );

        window.location.href = `${paycoUrl}&lng=${i18n.language}`;
      }
    } catch (e) {
      setIsLoading(false);
      toast({
        title: t('drawer.checkoutFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to initialize payco order', e);
    }
  };

  const doVoidReceipt = async () => {
    try {
      setIsLoading(true);
      const tokenResult = await instance.acquireTokenSilent(tokenRequest);
      const cartVoided = await voidCart(state.cart.id, tokenResult.accessToken);
      const cart = await initializeCart(posDetails, tokenResult.accessToken);
      // initialize a new cart
      dispatch({ type: 'INITIALIZE_CART', cart });
      dispatch({ type: 'CLOSE_DRAWER' });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast({
        title: t('drawer.voidReceiptFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed void receipt', e);
    }
  };

  const styleCancel = {
    background: 'red',
  };

  return (
    <Drawer
      isOpen={state.showDrawer}
      placement="right"
      onClose={() => dispatch({ type: 'CLOSE_DRAWER' })}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {state.cart.products.length > 0 && (
            <Flex>
              <IconButton
                isLoading={isLoading}
                mr="5px"
                icon={<DeleteIcon />}
                aria-label=""
                size="sm"
                colorScheme="red"
                // onClick={() => doVoidReceipt()}
                onClick={() => onOpen()}
              />
              <ModalYesOrNo
                headerText={t('drawer.voidReceiptHeader')}
                bodyText={t('drawer.voidReceiptBody')}
                isOpen={isOpen}
                onClose={onClose}
                onOk={doVoidReceipt}
              />
            </Flex>
          )}

          {t('catalog.cart')}
        </DrawerHeader>
        <DrawerBody overflowY="hidden">
          <Flex flexDir="column" alignContent="space-evenly" h="100%">
            {state.cart.customer !== null &&
              state.cart.customer?.customerID !== -1 && (
                <CartCustomerRow customer={state.cart.customer} />
              )}
            {state.cart.receiptInfo !== '' && (
              <CartReceiptInfoRow receiptInfo={state.cart.receiptInfo} />
            )}
            <Flex w="100%" flexDir="row" justifyContent="flex-start">
              <Button
                mr="5px"
                size="sm"
                onClick={() => dispatch({ type: 'OPEN_RECEIPTINFO_MODAL' })}
              >
                {t('drawer.setReceiptInfo')}
              </Button>
            </Flex>
            <Flex pt={5}>
              <Divider borderWidth="2px" />
            </Flex>
            <Flex flex="1" mt="5px" w="100%" overflowY="auto">
              <CartProductRows />
            </Flex>
          </Flex>
        </DrawerBody>

        <DrawerFooter w="100%">
          <Flex w="100%" flexDir="column">
            {state.cart.products.length > 0 && (
              <Flex w="100%" flexDir="row" justifyContent="flex-start">
                <Box>
                  <Text fontSize="larger" fontWeight="semibold">{`${t(
                    'drawer.total'
                  )} ${state.cart.receiptTotal.toFixed(2)}€`}</Text>
                </Box>
              </Flex>
            )}
            <Flex w="100%" flexDir="row" justifyContent="flex-end">
              {testing && (
                <Button variant="ghost" mr={3} onClick={() => doCashPayment()}>
                  Demo maksu
                </Button>
              )}
              <Button
                variant="ghost"
                mr={3}
                onClick={() => dispatch({ type: 'CLOSE_DRAWER' })}
              >
                {t('drawer.button.close')}
              </Button>
              {state.cart.products.length > 0 &&
                state.cart.payments.length === 0 && (
                  <Button isLoading={isLoading} onClick={() => doCheckout()}>
                    {t('drawer.button.checkout')}
                  </Button>
                )}
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CartDrawer;
