import { FC, useState, useContext } from 'react';
import {
  Button,
  Center,
  FormControl,
  Input,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { Redirect } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { tokenRequest } from '../../../authConfig';
import { addProductRow, getProductDetails } from '../../../helpers/api';
import { CartDrawer } from '../../components/CartDrawer';
import ProductCardScanned from '../../components/ProductCardScanned';
import CatalogProduct from '../../../models/catalogProduct';

const Scan: FC = () => {
  const { state: user } = useContext(UserContext);
  const { state: cart, dispatch: cartDispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const [showIncorrectError, setShowIncorrectError] = useState(false);
  const [showNotAllowedError, setShowNotAllowedError] = useState(false);
  const [showAppendError, setShowAppendError] = useState(false);
  const [showNotFoundError, setShowNotFoundError] = useState(false);
  const [code, setCode] = useState('');
  const [queryCode, setQueryCode] = useState('');
  const [product, setProduct] = useState<CatalogProduct>();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [productLoading, setProductLoading] = useState(false);

  const addToCart = async () => {
    setIsSaving(true);
    if (!code || code === '') return;
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const updatedCart = await addProductRow(
        cart.cart.id,
        code,
        1,
        result.accessToken
      );
      cartDispatch({ type: 'UPDATE_CART', cart: updatedCart });
      cartDispatch({ type: 'NOTIFY', text: t('drawer.productAdded') });
      setQueryCode('');
      setCode('');
      setProduct(undefined);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to add product', e);
      setShowAppendError(true);

      // // error code 003 means that the user should set product price by hand
      // if (Array.isArray(e) && e.some((x: IApiError) => x.code === '003')) {
      //   dispatch({ type: 'OPEN_PRICE_MODAL', product });
      // }

      // other error codes are errors that should show an error alert
    }
    setIsSaving(false);
  };

  const fetchProduct = async (productCode: string) => {
    try {
      setShowNotFoundError(false);
      if (!productCode || productCode === '') {
        setProduct(undefined);
        return;
      }
      setProductLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      const newProductDetails = await getProductDetails(
        productCode,
        user.posDetails ? user.posDetails.shopCode : '',
        result.accessToken
      );
      if (newProductDetails) {
        setProduct(newProductDetails);
      }
      setShowNotFoundError(!newProductDetails);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch product details', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setProductLoading(false);
  };

  const handleEnteredCode = (enteredCode: string) => {
    try {
      setQueryCode(enteredCode);
      fetchProduct(enteredCode);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.info('Code handling error', enteredCode, error);
      setShowIncorrectError(true);
    }
  };
  const rejectEnteredCode = () => {
    setQueryCode('');
    setCode('');
    setProduct(undefined);
  };

  const handleBarcodeResult = (result: string) => {
    if (code) return;
    try {
      setShowIncorrectError(false);
      setCode(result);
      handleEnteredCode(result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.info("QR codes data wasn't recognized", error);
      setShowIncorrectError(true);
    }
  };

  const handleBarcodeError = (error: string | DOMException) => {
    switch (error instanceof DOMException ? error.name : error) {
      default:
        break;
      case 'NotFoundException':
        break;
      case 'NotAllowedError':
        setShowNotAllowedError(true);
        break;
      case 'Permission denied':
        setShowNotAllowedError(true);
        break;
    }
  };

  const getScannerHeight = () => {
    return Math.round(window.innerHeight / 3);
  };

  const getBarcodeReader = () => {
    if (showNotAllowedError)
      return (
        <Center pt="5">
          <Text color="red.500" m="auto" whiteSpace="pre-line">
            {t('scan.cameraNotAllowed')}
          </Text>
        </Center>
      );
    return (
      <>
        <h4>{t('scan.HeadingBarcode')}</h4>
        <BarcodeScannerComponent
          delay={500}
          onUpdate={(_, result) => {
            if (result) {
              handleBarcodeResult(result.getText());
            }
          }}
          onError={(error) => handleBarcodeError(error)}
          videoConstraints={{ facingMode: 'environment' }}
        />
      </>
    );
  };

  if (cart.cart.id === '') {
    return <Redirect to="/" />;
  }

  return (
    // <Center w="full" px="5" h="100%">
    <Flex
      display="flex"
      flexDir="column"
      h="100%"
      w="100%"
      pl="20px"
      pr="20px"
      pt="20px"
    >
      <Flex display="flex" flexDir="column" h="100%" w="100%">
        {user.posDetails && <CartDrawer posDetails={user.posDetails} />}
        {code === '' && getBarcodeReader()}
        <h4>{t('scan.HeadingEntry')}</h4>
        {showIncorrectError && (
          <Center pt="5">
            <Text color="red.500" m="auto" whiteSpace="pre-line">
              {t('scan.incorrectCode')}
            </Text>
          </Center>
        )}
        {showAppendError && (
          <Center pt="5">
            <Text color="red.500" m="auto" whiteSpace="pre-line">
              {t('scan.appendError')}
            </Text>
          </Center>
        )}
        <form
          itemRef="form"
          onSubmit={(e) => {
            e.preventDefault();
            if (code === '') return;
            handleEnteredCode(code);
          }}
        >
          <FormControl pt="2" id="code">
            <Input
              // autoFocus
              autoComplete="off"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              type="number"
              placeholder={`${t('scan.code')}`}
              readOnly={queryCode !== ''}
            />
          </FormControl>
        </form>
        {product && <ProductCardScanned product={product} />}
        {showNotFoundError && (
          <Center pt="5">
            <Text color="red.500" m="auto" whiteSpace="pre-line">
              {t('scan.notFound')}
            </Text>
          </Center>
        )}{' '}
      </Flex>
      {queryCode === '' && (
        <Button
          variant={code === '' ? 'disabled' : 'primary'}
          isLoading={isSaving}
          disabled={code === ''}
          onClick={() => handleEnteredCode(code)}
          mt="16px"
          mb="24px"
          h="57px"
          isFullWidth
          boxShadow="md"
        >
          {t('scan.buttonReady')}
        </Button>
      )}
      {queryCode !== '' && (
        <>
          <Button
            variant={isSaving ? 'disabled' : 'secondary'}
            isLoading={isSaving}
            disabled={isSaving}
            onClick={() => rejectEnteredCode()}
            // mt="16"
            h="57px"
            isFullWidth
            boxShadow="md"
          >
            {t('button.cancel')}
          </Button>
          <Button
            variant={productLoading || isSaving ? 'disabled' : 'primary'}
            isLoading={isSaving}
            disabled={productLoading || isSaving}
            onClick={() => addToCart()}
            mt="16px"
            mb="24px"
            h="57px"
            isFullWidth
            boxShadow="md"
          >
            {t('button.addProduct')}
          </Button>
        </>
      )}
    </Flex>
    // </Center>
  );
};

export default Scan;
