import { FC, useState } from 'react';
import { Box, Input, Flex, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ICartProductInfoRow {
  ProductInfo: string;
  allowEdit?: boolean;
  newValue?(ProductInfo: string): void;
}

const CartProductInfoRow: FC<ICartProductInfoRow> = ({
  ProductInfo,
  allowEdit = false,
  newValue = null,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');

  if (isEditing) {
    return (
      <>
        <Box w="100%" mt="5px" mb="15px">
          {`${t('productInfoRow.heading')}: `}
          <Input
            autoFocus
            autoComplete="off"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            type="text"
          />
        </Box>
        <Flex w="100%" mt="5px" mb="5px" flexDir="row" justifyContent="center">
          <Button
            w="100%"
            mr="5px"
            variant="secondary"
            onClick={() => {
              setIsEditing(false);
            }}
            size="sm"
            isFullWidth
            boxShadow="md"
          >
            {t('productInfoRow.cancel')}
          </Button>
          <Button
            w="100%"
            ml="5px"
            variant="primary"
            onClick={() => {
              setIsEditing(false);
              if (ProductInfo !== editValue && newValue) {
                newValue(editValue);
              }
            }}
            size="sm"
            isFullWidth
            boxShadow="md"
          >
            {t('productInfoRow.save')}
          </Button>
        </Flex>
      </>
    );
  }
  return (
    <>
      {allowEdit && (
        <Flex w="100%" flexDir="row" justifyContent="left">
          <Button
            // w="100%"
            variant="secondary"
            onClick={() => {
              setEditValue(ProductInfo);
              setIsEditing(true);
            }}
            mt="16px"
            size="sm"
            // isFullWidth
            boxShadow="md"
          >
            {t('productInfoRow.setProductInfo')}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default CartProductInfoRow;
