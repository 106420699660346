import { Box, Center, Flex, Heading } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { UserContext } from '../../../context/UserContext';

// TODO: Do mobile design when it exists.
const Footer: FC = () => {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserContext);

  if (userState.operator === null) {
    return null;
  }

  if (isMobile) {
    return null;
  }
  return (
    <Flex
      flex="0"
      justifySelf="flex-end"
      boxShadow="lg"
      flexDir="column"
      width="full"
      pt="2"
      pb="4"
      bg="brand.primary"
    >
      <Box h="96px">
        <Center>
          <Heading as="h1" color="brand.secondary">
            {t('appName')}
          </Heading>
        </Center>
      </Box>
    </Flex>
  );
};

export default Footer;
