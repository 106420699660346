import IPosDetails from '../models/posDetails';

// USERS
export const getUserFromLocalstorage = (): string | null => {
  const currentUser = localStorage.getItem('posmanagerBasketUser');
  return currentUser ? JSON.parse(currentUser) : currentUser;
};

export const removeUser = (): void => {
  localStorage.removeItem('posmanagerBasketUser');
};

export const setUser = (operator: string): boolean => {
  try {
    localStorage.setItem('posmanagerBasketUser', JSON.stringify(operator));
    return true;
  } catch (e) {
    return false;
  }
};

// POS_DETAILS
export const getPosDetailsFromLocalstorage = (): IPosDetails | null => {
  const posDetails = localStorage.getItem('posmanagerBasketPosDetails');
  return posDetails ? JSON.parse(posDetails) : posDetails;
};

export const removePosDetails = (): void => {
  localStorage.removeItem('posmanagerBasketPosDetails');
};

export const setPosDetails = (posDetails: IPosDetails): boolean => {
  try {
    const currentPosDetails =
      getPosDetailsFromLocalstorage() === null
        ? posDetails
        : getPosDetailsFromLocalstorage();

    if (posDetails.shopCode !== '' && currentPosDetails !== null)
      currentPosDetails.shopCode = posDetails.shopCode;
    if (posDetails.posCode !== '' && currentPosDetails !== null)
      currentPosDetails.posCode = posDetails.posCode;
    if (posDetails.printerType !== '' && currentPosDetails !== null)
      currentPosDetails.printerType = posDetails.printerType;
    if (posDetails.terminalCode !== '' && currentPosDetails !== null)
      currentPosDetails.terminalCode = posDetails.terminalCode;

    localStorage.setItem(
      'posmanagerBasketPosDetails',
      JSON.stringify(currentPosDetails)
    );
    return true;
  } catch (e) {
    return false;
  }
};

// Order id

export const getOrderIdFromLocalStorage = (): string | null => {
  const orderId = localStorage.getItem('posmanagerBasketOrderId');
  return orderId ? JSON.parse(orderId) : orderId;
};

export const setOrderIdToLocalStorage = (orderId: string): boolean => {
  try {
    localStorage.setItem('posmanagerBasketOrderId', JSON.stringify(orderId));
    return true;
  } catch (e) {
    return false;
  }
};
