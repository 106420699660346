import { Flex } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { CartDrawer } from '../../components/CartDrawer';
import Search from '../../components/Search';
import { ProductInfoModal } from '../../components/ProductInfoModal';
import { ProductPriceModal } from '../../components/ProductPriceModal';
import { ReceiptInfoModal } from '../../components/ReceiptInfoModal';
import { CustomerInfoModal } from '../../components/CustomerInfoModal';

const Catalog: FC = () => {
  const { state: user } = useContext(UserContext);
  const { state: cart } = useContext(CartContext);
  const { t } = useTranslation();

  // redirect user to read qr code if pos settings are missing
  if (user.posDetails === null) {
    return <Redirect to="/settings" />;
  }

  return (
    <>
      <Flex flex="1" flexDir="column">
        <Flex flexDir="column" mt="5" pb="25">
          <Search posDetails={user.posDetails} />
        </Flex>
      </Flex>
      <CartDrawer posDetails={user.posDetails} />
      {cart.showReceiptInfoModal && <ReceiptInfoModal />}
      {cart.showModal && <ProductInfoModal />}
      {cart.showPriceModal && <ProductPriceModal />}
      {cart.showCustomerModal && <CustomerInfoModal />}
    </>
  );
};

export default Catalog;
