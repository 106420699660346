import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
// import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { tokenRequest } from '../../../authConfig';
import { UserContext } from '../../../context/UserContext';
import { CartContext } from '../../../context/CartContext';
import { getCart, getReceipt, printReceipt } from '../../../helpers/api';
import { setOrderIdToLocalStorage } from '../../../helpers/localStorageHelper';
import Cart from '../../../models/cart';
import Customer from '../../../models/customer';
import Operator from '../../../models/operator';
import Payment from '../../../models/payment';
import Product from '../../../models/product';
import Shop from '../../../models/shop';
import Parameter from '../../../models/parameter';

const printerType = process.env.REACT_APP_PRINTER_TYPE || ''; // payco, windows
// const printerType = 'windows';

const Finalize: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [receiptHtml, setReceiptHtml] = useState('');
  const [receiptBlob, setReceiptBlob] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);
  const [loadFailed, setLoadFailed] = useState(false);
  const toast = useToast({ variant: 'subtle' });
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(CartContext);
  const cart = new Cart(
    '',
    '',
    -1,
    new Array<Product>(),
    new Array<Payment>(),
    new Customer(-1, '', '', '', '', '', '', '', '', '', '', '', ''),
    -1,
    new Operator('', ''),
    '',
    new Shop('', ''),
    0,
    new Array<string>(),
    new Array<Parameter>()
  );

  const getReceiptData = async () => {
    try {
      setIsLoading(true);
      setLoadFailed(false);
      const result = await instance.acquireTokenSilent(tokenRequest);
      const blobResult = await getReceipt(id, 'png', result.accessToken);
      setReceiptBlob(blobResult);
      const receiptData = await getReceipt(id, 'html', result.accessToken);
      setReceiptHtml(receiptData);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setLoadFailed(true);
      toast({
        title: t('finalize.receiptFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to fetch receipt data');
    }
  };

  const saveOrderId = async (cartId: string) => {
    const result = await instance.acquireTokenSilent(tokenRequest);
    const cartDetails = await getCart(cartId, result.accessToken);
    setOrderIdToLocalStorage(cartDetails.orderId);
  };

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const doPrintReceipt = async () => {
    if (state.posDetails === null) {
      toast({
        title: t('finalize.noPosDetails'),
        status: 'warning',
        isClosable: true,
      });
    } else {
      try {
        setIsPrinting(true);
        if (state.posDetails.printerType.toLowerCase() === 'windows') {
          // if (printerType === 'windows') {
          handlePrint();
        } else {
          const result = await instance.acquireTokenSilent(tokenRequest);
          await printReceipt(
            receiptBlob,
            'png',
            state.posDetails,
            result.accessToken
          );
        }
        setIsPrinting(false);
      } catch (e) {
        setIsPrinting(false);
        toast({
          title: t('finalize.printFailed'),
          status: 'error',
          isClosable: true,
        });
        // eslint-disable-next-line no-console
        console.error('Print failed');
      }
    }
  };

  useEffect(() => {
    saveOrderId(id);
    getReceiptData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadFailed || receiptBlob === '') {
      return;
    }
    dispatch({ type: 'INITIALIZE_CART', cart });
    doPrintReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptBlob]);

  return (
    <Flex flex="1" flexDir="column" mx="auto">
      {isLoading && (
        <Flex flexDir="column">
          <Heading size="lg">{t('finalize.loading')}</Heading>
          <Flex pt="1" justifyContent="center">
            <Spinner thickness="4px" speed="0.65s" size="xl" />
          </Flex>
        </Flex>
      )}
      <Flex mt="2" flexDir="column">
        {loadFailed && (
          <Button mb="2" onClick={() => getReceiptData()}>
            {t('finalize.tryAgain')}
          </Button>
        )}
        {!loadFailed && !isLoading && (
          <Button isLoading={isPrinting} onClick={() => doPrintReceipt()}>
            {t('finalize.printAgain')}
          </Button>
        )}
      </Flex>
      <div style={{ display: 'none' }}>
        <img
          ref={printRef}
          src={`data:image/png;base64,${receiptBlob}`}
          alt=""
        />
      </div>
      {/** FIXME: Replace the unsafe dangerouslySetInnerHTML with something better.
       * Should probably fetch cart data instead and generate users view from that and just pass receiptHtml to receipt print when thats done.
       */}
      {!isLoading && <div dangerouslySetInnerHTML={{ __html: receiptHtml }} />}
    </Flex>
  );
};

export default Finalize;
