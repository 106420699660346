import { Box, Button, Text, Flex, color } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import NotifyPopup from '../NotifyPopup';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { HomeIcon } from '../../../assets/icons/home';
import { CartIcon } from '../../../assets/icons/mobileCart';
import { ScanIcon } from '../../../assets/icons/scan';
import { SettingsIcon } from '../../../assets/icons/settings';

const AppHeader: FC = () => {
  const { state: cartState, dispatch: cartDispatch } = useContext(CartContext);
  const { state: userState } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { instance } = useMsal();

  const mobileHeight = '69px';

  const location = useLocation();
  useEffect(() => {}, [location.pathname]);
  useEffect(() => {}, [cartState.notifyText]);

  if (userState.operator === null) {
    return null;
  }

  const changeSearchMode = (searchMode: number) => {
    cartDispatch({ type: 'CHANGE_SEARCHMODE', searchMode });
    history.push('/');
  };

  const doSignOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  if (isMobile) {
    const colorSelected = '#FEDCA9';
    const colorUnselected = '#FFFFFF';
    const currentPath = location.pathname;
    const colorHome = currentPath === '/' ? colorSelected : colorUnselected;
    const colorCart = currentPath === '/cart' ? colorSelected : colorUnselected;
    const colorScan = currentPath === '/scan' ? colorSelected : colorUnselected;
    const colorSettings =
      currentPath === '/settings' ? colorSelected : colorUnselected;
    const popupTop = `calc(100% - ${mobileHeight} - 60px)`;

    return (
      <>
        <NotifyPopup top={popupTop} height="60px" width="100%" />
        <Flex
          w="100%"
          flexDir="row"
          justifyContent="center"
          bgColor="brand.primary"
          px="2"
          flex="0"
          boxShadow="md"
          p="0px 50px 0px 50px"
        >
          <Flex
            h={mobileHeight}
            flex="1"
            justifyContent="space-evenly"
            alignContent="center"
          >
            <Button variant="link" mx="5" as={RouterLink} to="/">
              <HomeIcon color={colorHome} width="28px" height="28px" />
            </Button>
            <Button variant="link" mx="5" as={RouterLink} to="/cart">
              <CartIcon color={colorCart} width="28px" height="28px" />
              <Text
                style={{ fontSize: '18px', fontWeight: 'bold' }}
                color={colorCart}
              >{`${cartState.cart.getProductCount()}`}</Text>
            </Button>
            <Button variant="link" mx="5" as={RouterLink} to="/scan">
              <ScanIcon color={colorScan} width="28px" height="28px" />
            </Button>
            <Button variant="link" mx="5" as={RouterLink} to="/settings">
              <SettingsIcon color={colorSettings} width="28px" height="28px" />
            </Button>
          </Flex>
        </Flex>
      </>
    );
  }
  return (
    <>
      <Flex
        w="100%"
        flexDir="row"
        justifyContent="center"
        bgColor="brand.primary"
        px="2"
        flex="0"
        boxShadow="md"
        pr="250px"
      >
        <Flex h="96px" flex="1" justifyContent="start" alignContent="center">
          <Flex flex="1" color="brand.secondary" alignSelf="center">
            <Button variant="link" mx="5" onClick={() => changeSearchMode(1)}>
              {t('appHeader.products')}
            </Button>
            <Button variant="link" mx="5" onClick={() => changeSearchMode(2)}>
              {t('appHeader.customers')}
            </Button>
            <Button
              variant="link"
              mx="5"
              as={RouterLink}
              to={cartState.cart.products?.length > 0 ? '' : '/cashdraw'}
              isDisabled={cartState.cart.products?.length > 0}
            >
              {t('appHeader.cashdraw')}
            </Button>
            <Button
              variant="link"
              mx="5"
              as={RouterLink}
              to={cartState.cart.products?.length > 0 ? '' : '/settings'}
              isDisabled={cartState.cart.products?.length > 0}
            >
              {t('appHeader.settings')}
            </Button>
          </Flex>
          <Flex justifyContent="end" alignSelf="center" color="brand.secondary">
            <Button variant="link" onClick={() => doSignOut()}>
              {t('appHeader.signOut')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Box
        w="xs"
        h="xs"
        bg="brand.pink"
        display="flex"
        position="absolute"
        right="-140"
        top="-20"
        zIndex="999"
        borderRadius="15%"
        transform="rotate(40deg)"
      >
        {userState.operator !== null &&
          userState.posDetails !== null &&
          cartState.cart.id !== '' && (
            <Flex
              flexDir="column"
              w="full"
              h="full"
              justifyContent="center"
              alignItems="start"
              transform="rotate(-40deg)"
              pt="10"
            >
              <Button
                mr="1"
                onClick={() => cartDispatch({ type: 'OPEN_DRAWER' })}
                variant="link"
                color="brand.200"
                fontSize="20px"
                fontWeight="600"
              >
                {t('appHeader.cart.cart')}
              </Button>
              <p>
                {`${cartState.cart.getProductCount()} 
                ${
                  cartState.cart.getProductCount() === 1
                    ? t('appHeader.cart.product')
                    : t('appHeader.cart.products')
                }`}
              </p>
              <p>{cartState.cart.receiptTotal.toFixed(2)}€</p>
            </Flex>
          )}
      </Box>
    </>
  );
};

export default AppHeader;
