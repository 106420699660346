import { FC, useContext, useState, useRef, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { CartContext } from '../../../context/CartContext';
import { addReceiptInfo } from '../../../helpers/api';
import { tokenRequest } from '../../../authConfig';

export const ReceiptInfoModal: FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const [receiptInfo, setReceiptInfo] = useState<string>(
    state.cart.receiptInfo
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const modalSize = useBreakpointValue({ base: 'full', sm: 'md' });
  const { instance } = useMsal();

  useEffect(() => {
    if (state.showReceiptInfoModal) {
      inputRef?.current?.focus();
    }
  }, [state.showReceiptInfoModal]);

  const updateReceiptInfo = async () => {
    setIsSaving(true);
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const cart = await addReceiptInfo(
        state.cart.id,
        receiptInfo,
        result.accessToken
      );
      dispatch({ type: 'UPDATE_CART', cart });
      dispatch({ type: 'CLOSE_RECEIPTINFO_MODAL' });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to add receipt info', e);
      setIsSaving(false);
    }
  };

  return (
    <Modal
      isOpen={state.showReceiptInfoModal}
      onClose={() => dispatch({ type: 'CLOSE_RECEIPTINFO_MODAL' })}
      initialFocusRef={inputRef}
      size={modalSize}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('receiptInfo.heading')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {t('receiptInfo.info')}:
            <Input
              ref={inputRef}
              mt="1"
              value={receiptInfo}
              onChange={(e) => setReceiptInfo(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter' && !isSaving) {
                  updateReceiptInfo();
                }
              }}
              placeholder={t('receiptInfo.placeholder')}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            isFullWidth
            isLoading={isSaving}
            onClick={() => updateReceiptInfo()}
          >
            {t('receiptInfo.update')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReceiptInfoModal;
