import {
  FC,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { isMobile } from 'react-device-detect';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  Spinner,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import CatalogProduct from '../../../models/catalogProduct';
import {
  getCustomers,
  getProducts,
  getShortcuts,
  initializeCart,
  getProductDetails,
  addProductRow,
} from '../../../helpers/api';
import MobileHeader from '../MobileHeader';
import ProductCard from '../ProductCard';
import { SkeletonCard } from '../SkeletonCard';
import IPosDetails from '../../../models/posDetails';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import Cart from '../../../models/cart';
import Customer from '../../../models/customer';
import Shortcut from '../../../models/shortcut';
import { CustomerCard } from '../CustomerCard';
import { tokenRequest } from '../../../authConfig';
import ShortcutCard from '../ShortcutCard';
import { IApiError } from '../../../models/apierror';

interface ISearchProps {
  posDetails: IPosDetails;
}

const Search: FC<ISearchProps> = ({ posDetails }) => {
  const toast = useToast({ variant: 'subtle' });
  const { t } = useTranslation();
  const { state, dispatch } = useContext(CartContext);
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const [productCatalog, setProductCatalog] = useState<Array<CatalogProduct>>(
    new Array<CatalogProduct>()
  );
  const [products, setProducts] = useState<Array<CatalogProduct>>(
    new Array<CatalogProduct>()
  );
  const [shortcuts, setShortcuts] = useState<Array<Shortcut>>(
    new Array<Shortcut>()
  );
  const [shortcutCategories, setShortcutCategories] = useState<Array<string>>(
    new Array<string>()
  );
  const [customerCatalog, setCustomerCatalog] = useState<Array<Customer>>(
    new Array<Customer>()
  );
  const [customers, setCustomers] = useState<Array<Customer>>(
    new Array<Customer>()
  );
  const [input, setInput] = useState('');
  const [productLoading, setProductLoading] = useState(true);
  const [customerLoading, setCustomerLoading] = useState(true);
  const searchField = useRef<HTMLInputElement>(null);
  const [cartFailed, setCartFailed] = useState(false);
  const { instance } = useMsal();

  const skeleton = [
    <SkeletonCard key="1" />,
    <SkeletonCard key="2" />,
    <SkeletonCard key="3" />,
    <SkeletonCard key="4" />,
    <SkeletonCard key="5" />,
    <SkeletonCard key="6" />,
    <SkeletonCard key="7" />,
    <SkeletonCard key="8" />,
  ];

  const [productDetails, setProductDetails] = useState<CatalogProduct>(
    new CatalogProduct(0, '', '', '', 0, '', '', 0, false, null)
  );

  const doUpdateParameters = async (cart: Cart) => {
    if (cart.posParameters !== undefined) {
      let parameter = cart.posParameters.find(
        (x) => x.parameterCode === 'PrinterType'
      );
      if (parameter !== undefined && parameter.parameterValue !== '') {
        const newPosDetails = posDetails;
        newPosDetails.printerType = parameter.parameterValue;
        parameter = cart.posParameters.find(
          (x) => x.parameterCode === 'TerminalNumber'
        );
        if (parameter !== undefined && parameter.parameterValue !== '') {
          newPosDetails.terminalCode = parameter.parameterValue;
        }
        userDispatch({
          type: 'SET_POS_DETAILS',
          posDetails: newPosDetails,
        });
      }
    }
  };

  const doCartInitialization = useCallback(async () => {
    try {
      if (state.cart.id === '') {
        const result = await instance.acquireTokenSilent(tokenRequest);
        const cart = await initializeCart(posDetails, result.accessToken);
        dispatch({ type: 'INITIALIZE_CART', cart });
        doUpdateParameters(cart);
      }
      setCartFailed(false);
      searchField?.current?.focus();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('App initialization failed', e);
      toast({
        title: t('catalog.initializationFailed'),
        status: 'error',
        isClosable: true,
      });
      setCartFailed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posDetails]);

  useEffect(() => {
    doCartInitialization();
  }, [doCartInitialization]);

  const fetchProducts = useCallback(async () => {
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const newProducts = await getProducts(
        posDetails.shopCode,
        result.accessToken
      );
      setProductLoading(false);
      setProductCatalog(newProducts);
      setProducts(newProducts);
    } catch (e) {
      setProductLoading(false);
      toast({
        title: t('catalog.productFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to fetch products', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posDetails]);

  const fetchShortcuts = useCallback(async () => {
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const newShortcuts = await getShortcuts(
        posDetails.shopCode,
        result.accessToken
      );
      setProductLoading(false);
      setShortcuts(newShortcuts);
    } catch (e) {
      setProductLoading(false);
      toast({
        title: t('catalog.productFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to fetch productdetails', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posDetails]);

  const fetchCustomers = useCallback(async (query: string) => {
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const newCustomers = await getCustomers(
        query,
        posDetails.shopCode,
        result.accessToken
      );
      setCustomerLoading(false);
      setCustomerCatalog(newCustomers);
      setCustomers(newCustomers);
    } catch (e) {
      setProductLoading(false);
      toast({
        title: t('catalog.customerFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to fetch customers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const distinctList = shortcuts
      .map((item) => item.shortcutGroupName)
      .filter((value, index, self) => self.indexOf(value) === index);
    // console.log(distinctList);
    setShortcutCategories(distinctList);
  }, [shortcuts]);

  useEffect(() => {
    searchField?.current?.focus();
    // fetchProducts();
    fetchShortcuts();
    // fetchCustomers();
  }, [fetchShortcuts]);

  useEffect(() => {
    const newList = [
      ...productCatalog.filter((x) =>
        x.productName.toLowerCase().includes(input.toLowerCase())
      ),
    ];
    if (newList !== undefined) setProducts(newList);
  }, [input, productCatalog]);

  useEffect(() => {
    const newList = [
      ...customerCatalog.filter((x) =>
        x.customerName.toLowerCase().includes(input.toLowerCase())
      ),
    ];
    if (newList !== undefined) setCustomers(newList);
  }, [input, customerCatalog]);

  useEffect(() => {
    searchField?.current?.focus();
  }, [state.searchMode]);

  const fetchProductDetails = async (productCode: string) => {
    try {
      // console.log('fetchProductDetails');
      if (productCode === productDetails.productCode) return productDetails;

      setProductLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      const newProductDetails = await getProductDetails(
        productCode,
        state.cart.shop.shopCode,
        result.accessToken
      );
      setProductLoading(false);
      setProductDetails(newProductDetails);
      return newProductDetails;
    } catch (e) {
      setProductLoading(false);
      toast({
        title: t('scan.notFound'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to fetch productdetails', e);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const addToCart = async (product: CatalogProduct) => {
    // setIsSaving(true);
    try {
      // console.log('addToCart');
      const result = await instance.acquireTokenSilent(tokenRequest);
      const cart = await addProductRow(
        state.cart.id,
        product.productCode,
        1,
        result.accessToken
      );
      dispatch({ type: 'UPDATE_CART', cart });
      dispatch({ type: 'NOTIFY', text: t('drawer.productAdded') });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to add product (search)', e);
      // error code 003 means that the user should set product price by hand
      if (Array.isArray(e) && e.some((x: IApiError) => x.code === '003')) {
        dispatch({ type: 'OPEN_PRICE_MODAL', product });
      }
      // other error codes are errors that should show an error alert
    }
    // setIsSaving(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // console.log('handleKeyDown');
      if (state.searchMode === 1) {
        fetchProductDetails(input)
          .then((result) => {
            if (result !== null) {
              addToCart(result);
            }
          })
          .finally(() => {
            setInput('');
          });
      }
      if (state.searchMode === 2) {
        fetchCustomers(input).finally(() => {
          setInput('');
        });
      }
    }
  };

  if (!cartFailed && state.cart.id !== '') {
    return (
      <Flex w="100%" flexDir="column">
        {!isMobile && (
          <>
            <Center>
              <Text mb="2">{`${state.cart.shop.shopName} ${
                state.cart.posNumber === -1 ? '' : state.cart.posNumber
              }`}</Text>
            </Center>
            <Flex mb="5" w="100%" justifyContent="center">
              <InputGroup w={['80%', '75%', '65%', '50%']}>
                <Input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  ref={searchField}
                  placeholder={
                    state.searchMode === 1
                      ? t('scan.code')
                      : t('catalog.filter.customers')
                  }
                />
              </InputGroup>
            </Flex>
            <Flex>
              <Flex flexWrap="wrap" justifyContent="center" width="100%">
                {productLoading && state.searchMode === 1 && skeleton}
                {state.searchMode === 1 && (
                  <Tabs variant="unstyled">
                    <TabList justifyContent="center">
                      {shortcutCategories.length > 0 &&
                        shortcutCategories.map((x) => (
                          <Tab
                            _selected={{
                              color: 'brand.white',
                              bg: 'brand.blue',
                              outlineColor: 'brand.blue',
                              outlineOffset: '0px',
                            }}
                            outlineColor="brand.blue"
                            outlineOffset="0px"
                            rounded={5}
                            marginRight={3}
                            marginLeft={3}
                            minWidth="7em"
                          >
                            {x}
                          </Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                      {shortcutCategories.length > 0 &&
                        shortcutCategories.map((group) => (
                          <TabPanel>
                            <Flex
                              flexWrap="wrap"
                              justifyContent="center"
                              width="100%"
                            >
                              {shortcuts
                                ?.filter(
                                  (y) =>
                                    y.parameters.length > 0 &&
                                    y.command === 'AddProductByProductCode' &&
                                    y.shortcutGroupName === group
                                )
                                .map((x) => (
                                  <ShortcutCard
                                    key={x.shortcutID}
                                    shortcut={x}
                                  />
                                ))}
                            </Flex>
                          </TabPanel>
                        ))}
                    </TabPanels>
                  </Tabs>
                )}
                {customerLoading && state.searchMode === 2 && skeleton}
                {state.searchMode === 2 &&
                  customerCatalog.length > 0 &&
                  customers?.map((x) => (
                    <CustomerCard key={x.customerID} customer={x} />
                  ))}
              </Flex>
            </Flex>
          </>
        )}
        {isMobile && (
          <div style={{ marginLeft: '20px', marginRight: '20px' }}>
            <MobileHeader caption={t('catalog.products')} />
            <Flex ml="0px">
              <Flex
                ml="-8px"
                flexWrap="wrap"
                justifyContent="center"
                width="100%"
              >
                {productLoading && state.searchMode === 1 && skeleton}
                {customerLoading && state.searchMode === 2 && skeleton}
                {state.searchMode === 1 &&
                  shortcuts.length > 0 &&
                  shortcuts
                    ?.filter(
                      (y) =>
                        y.parameters.length > 0 &&
                        y.command === 'AddProductByProductCode'
                    )
                    .map((x) => (
                      <ShortcutCard key={x.shortcutID} shortcut={x} />
                    ))}
                {state.searchMode === 2 &&
                  customerCatalog.length > 0 &&
                  customers?.map((x) => (
                    <CustomerCard key={x.customerID} customer={x} />
                  ))}
              </Flex>
            </Flex>
          </div>
        )}
      </Flex>
    );
  }

  if (cartFailed) {
    return (
      <Flex w="100%" flexDir="column">
        <Center>
          <Heading as="h4" size="md">
            {t('catalog.initializationFailedHeading')}
          </Heading>
        </Center>
        <Center mt="5">
          <Button onClick={() => doCartInitialization()}>
            {t('catalog.tryInitialization')}
          </Button>
        </Center>
      </Flex>
    );
  }

  return (
    <Center>
      <Spinner />
    </Center>
  );
};

export default Search;
