import { FC, useContext, useEffect, useState } from 'react';
import { Flex, Button, useToast } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import MobileHeader from '../../components/MobileHeader';
// import CartCustomerRow from '../../components/CartCustomerRow';
import CartReceiptInfoRow from '../../components/CartReceiptInfoRow';
import CartProductRows from '../../components/CartProductRows';
import { UserContext } from '../../../context/UserContext';
import { CartContext } from '../../../context/CartContext';
import { initializePayco, addCashPayment } from '../../../helpers/api';
import { tokenRequest } from '../../../authConfig';

// If true Cashpayment without Payco, only for testing purposes
const testing = false;

const Cart: FC = () => {
  const { state: userState } = useContext(UserContext);
  const { state } = useContext(CartContext);
  const [currentReceiptInfo, setReceiptInfo] = useState(state.cart.receiptInfo);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const toast = useToast({ variant: 'subtle' });
  const history = useHistory();
  const { instance } = useMsal();

  useEffect(() => {}, [currentReceiptInfo]);

  const setNewReceiptInfo = (receiptInfo: string) => {
    state.cart.receiptInfo = receiptInfo;
    setReceiptInfo(receiptInfo);
  };

  const doCashPayment = async () => {
    try {
      setIsLoading(true);
      const tokenResult = await instance.acquireTokenSilent(tokenRequest);
      const paycoUrl = await addCashPayment(
        state.cart.id,
        '0',
        Math.round(state.cart.receiptTotal),
        tokenResult.accessToken
      );
      history.push(`/finalize/${state.cart.id}`);
    } catch (e) {
      setIsLoading(false);
      toast({
        title: t('drawer.checkoutFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed cash payment', e);
    }
  };

  const doCheckout = async () => {
    try {
      if (userState.posDetails) {
        setIsLoading(true);

        if (state.cart.receiptTotal === 0 && state.cart.products.length > 0) {
          doCashPayment();
        } else {
          const tokenResult = await instance.acquireTokenSilent(tokenRequest);

          const paycoUrl = await initializePayco(
            state.cart,
            userState.posDetails,
            tokenResult.accessToken
          );

          window.location.href = `${paycoUrl}&lng=${i18n.language}`;
        }
      }
    } catch (e) {
      setIsLoading(false);
      toast({
        title: t('drawer.checkoutFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to initialize payco order', e);
    }
  };

  return (
    <>
      {userState.posDetails != null && (
        <div style={{ margin: '20px 20px 0px 20px', height: '100%' }}>
          <Flex display="flex" flexDir="column" h="100%">
            {/* {state.cart.customer !== null &&
                state.cart.customer?.customerID !== -1 && (
                  <CartCustomerRow customer={state.cart.customer} />
                )} */}
            <MobileHeader caption={t('catalog.cart')} />
            <Flex flex="1" mt="auto" w="100%" overflowY="auto" h="auto">
              <CartProductRows />
            </Flex>
            <CartReceiptInfoRow
              receiptInfo={state.cart.receiptInfo}
              allowEdit
              newValue={setNewReceiptInfo}
            />
            <Button
              variant="primary"
              w="100%"
              onClick={() => doCheckout()}
              mt="16px"
              mb="24px"
              h="57px"
              isFullWidth
              boxShadow="md"
            >
              {t('drawer.button.checkout')}
            </Button>
            {testing && (
              <Button
                variant="primary"
                w="100%"
                onClick={() => doCashPayment()}
                mt="16px"
                mb="24px"
                h="57px"
                isFullWidth
                boxShadow="md"
              >
                {t('Demo maksu')}
              </Button>
            )}
          </Flex>
        </div>
      )}
    </>
  );
};

export default Cart;
