import { FC, useContext, useEffect } from 'react';
import { Box, Center, Flex, Spinner } from '@chakra-ui/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import AppHeader from './components/AppHeader';
import Settings from './pages/Settings';
import Catalog from './pages/Catalog';
import Footer from './components/Footer';
import Finalize from './pages/Finalize';
import { UserContext } from '../context/UserContext';
import MsalLogin from './components/MsalLogin';
import Cancelled from './pages/Cancelled';
import { loginRequest } from '../authConfig';
import languageHelper from '../helpers/languageHelper';
import Scan from './pages/Scan';
import Cashdraw from './pages/Cashdraw';
import Cart from './pages/Cart';

const AppContent: FC = ({ children }) => {
  const { state } = useContext(UserContext);

  if (state.operator === null) {
    return (
      <Flex flexDir="column" flex="1" overflowY="auto">
        <Center>
          <Spinner />
        </Center>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" flex="1" overflowY="auto">
      {children}
    </Flex>
  );
};

const App: FC = () => {
  const { dispatch } = useContext(UserContext);
  const { instance, accounts } = useMsal();
  const { i18n } = useTranslation();

  useEffect(() => {
    const getUserDetails = async () => {
      if (accounts.length > 0) {
        try {
          // trying to find corrent account, if thera are more than 1 availble
          if (accounts.length > 1) {
            const account = accounts.find(
              (x) =>
                (x.idTokenClaims as { aud: string }).aud ===
                instance.getConfiguration().auth.clientId
            );
            instance.setActiveAccount(
              account !== undefined ? account : accounts[0]
            );
          } else {
            instance.setActiveAccount(accounts[0]);
          }
          // console.log('Active account', instance.getActiveAccount());
          // trying to get token without saving just to provoke login if refresh token has expired
          await instance.acquireTokenSilent({
            ...loginRequest,
          });
          if (instance.getActiveAccount()?.idTokenClaims !== null) {
            const claims = instance.getActiveAccount()?.idTokenClaims as {
              country: string;
            }; // FIXME: too lazy to create an interface for claims
            // Me too, but those warnings are so annoying

            i18n.changeLanguage(languageHelper(claims.country));
            dispatch({
              type: 'LOGIN_USER',
              operator: instance.getActiveAccount()?.name,
            });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Something went wrong, please refresh the page', e);
          await instance.loginRedirect(); // FIXME: This feels wrong.
        }
      }
    };
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, accounts]);

  return (
    <Router>
      <Flex display="flex" flexDir="column" bg="gray.50" h="full">
        <UnauthenticatedTemplate>
          <MsalLogin />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          {!isMobile && <AppHeader />}
          {/* <AppHeader /> */}
          <AppContent>
            <Switch>
              <Route exact path="/">
                <Catalog />
              </Route>
              <Route exact path="/finalize/:id">
                <Finalize />
              </Route>
              <Route exact path="/settings">
                <Settings isQrCodeInUse={false} />
              </Route>
              <Route exact path="/basket/:id">
                <Cancelled />
              </Route>
              <Route exact path="/cancel/:id">
                <Finalize />
              </Route>
              <Route exact path="/scan">
                <Scan />
              </Route>
              <Route exact path="/cart">
                <Cart />
              </Route>
              <Route exact path="/cashdraw">
                <Cashdraw />
              </Route>
              {/* A route with no path is a fallback route for 404 */}
              <Route>
                <Box h="100%">404</Box>
              </Route>
            </Switch>
            <Footer />
          </AppContent>
          {isMobile && <AppHeader />}
        </AuthenticatedTemplate>
      </Flex>
    </Router>
  );
};

export default App;
