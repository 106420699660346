import { FC, useState, SetStateAction, Dispatch, useContext } from 'react';
import {
  Box,
  Input,
  Flex,
  Button,
  HStack,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { tokenRequest } from '../../../authConfig';
import { CartContext } from '../../../context/CartContext';
import { addProductRowDiscount } from '../../../helpers/api';
import Product from '../../../models/product';

interface ICartProductRow {
  product: Product;
  editDiscount: boolean;
  setEditDiscount: Dispatch<SetStateAction<boolean>>;
}

const CartProductDiscountRow: FC<ICartProductRow> = ({
  product,
  editDiscount = false,
  setEditDiscount,
}) => {
  const { state, dispatch } = useContext(CartContext);
  const { t } = useTranslation();
  const [amountValue, setAmountValue] = useState('');
  const [percentValue, setPercentValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { instance } = useMsal();

  const updateDiscount = async () => {
    try {
      console.log('save4');
      setIsLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      // call product discount api
      const cart = await addProductRowDiscount(
        state.cart.id,
        product.rowId,
        result.accessToken,
        parseFloat(amountValue === '' ? '0' : amountValue),
        parseFloat(percentValue === '' ? '0' : percentValue)
      );
      // update cart state
      dispatch({ type: 'UPDATE_CART', cart });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to update discount to product row', e);
      setIsLoading(false);
    }
  };

  if (editDiscount) {
    return (
      <>
        <HStack>
          <Box w="100%">
            {`${t('drawer.discountAmount')}: `}
            <NumberInput
              autoFocus
              autoComplete="off"
              min={0}
              max={Math.abs(product.totalAmount)}
              precision={2}
              value={amountValue}
              onChange={(valueString) => setAmountValue(valueString)}
            >
              <NumberInputField />
            </NumberInput>
          </Box>
          <Box w="100%">
            {`${t('drawer.discountPercent')}: `}
            <NumberInput
              autoComplete="off"
              min={0}
              max={100}
              precision={0}
              value={percentValue}
              onChange={(valueString) => setPercentValue(valueString)}
            >
              <NumberInputField />
            </NumberInput>
          </Box>
        </HStack>
        <Flex w="100%" mt="5px" mb="5px" flexDir="row" justifyContent="center">
          <Button
            w="100%"
            mr="5px"
            variant="secondary"
            onClick={() => {
              setEditDiscount(false);
            }}
            size="sm"
            isFullWidth
            boxShadow="md"
          >
            {t('productInfoRow.cancel')}
          </Button>
          <Button
            w="100%"
            ml="5px"
            variant="primary"
            onClick={() => {
              console.log('save1');
              setEditDiscount(false);
              console.log('save2');
              updateDiscount();
              console.log('save3');
            }}
            size="sm"
            isFullWidth
            boxShadow="md"
          >
            {t('productInfoRow.save')}
          </Button>
        </Flex>
      </>
    );
  }
  return <></>;
};

export default CartProductDiscountRow;
